import React, { useEffect, useState } from "react";
import query from "jquery";
import { Link, NavLink } from "react-router-dom";
const HeaderTwo = ({ category }) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
    const selectElement = query(".js-example-basic-single");
    selectElement.select2();

    return () => {
      if (selectElement.data("select2")) {
        selectElement.select2("destroy");
      }
    };
  }, []);

  // Set the default language
  //   const [selectedLanguage, setSelectedLanguage] = useState("Eng");
  //   const handleLanguageChange = (language) => {
  //     setSelectedLanguage(language);
  //   };

  //   // Set the default currency
  //   const [selectedCurrency, setSelectedCurrency] = useState("USD");
  //   const handleCurrencyChange = (currency) => {
  //     setSelectedCurrency(currency);
  //   };

  // Mobile menu support
  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleMenuClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };

  // Search control support
  const [activeSearch, setActiveSearch] = useState(false);
  const handleSearchToggle = () => {
    setActiveSearch(!activeSearch);
  };

  // category control support
  const [activeCategory, setActiveCategory] = useState(false);
  const handleCategoryToggle = () => {
    setActiveCategory(!activeCategory);
  };
  const [activeIndexCat, setActiveIndexCat] = useState(null);
  const handleCatClick = (index) => {
    setActiveIndexCat(activeIndexCat === index ? null : index);
  };

  return (
    <>
      <div className="overlay" />
      <div
        className={`side-overlay ${(menuActive || activeCategory) && "show"}`}
      />
      {/* ==================== Search Box Start Here ==================== */}

      <form action="#" className={`search-box ${activeSearch && "active"}`}>
        <button
          onClick={handleSearchToggle}
          type="button"
          className="search-box__close position-absolute inset-block-start-0 inset-inline-end-0 m-16 w-48 h-48 border border-gray-100 rounded-circle flex-center text-white hover-text-gray-800 hover-bg-white text-2xl transition-1"
        >
          <i className="ph ph-x" />
        </button>
        <div className="container">
          <div className="position-relative">
            <input
              type="text"
              className="form-control py-16 px-24 text-xl rounded-pill pe-64"
              placeholder="Search for a product or brand"
            />
            <button
              type="submit"
              className="w-48 h-48 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
            >
              <i className="ph ph-magnifying-glass" />
            </button>
          </div>
        </div>
      </form>
      {/* ==================== Search Box End Here ==================== */}
      {/* ==================== Mobile Menu Start Here ==================== */}
      <div
        className={`mobile-menu scroll-sm d-lg-none d-block ${
          menuActive && "active"
        }`}
      >
        <button
          onClick={() => {
            handleMenuToggle();
            setActiveIndex(null);
          }}
          type="button"
          className="close-button"
        >
          <i className="ph ph-x" />{" "}
        </button>
        <div className="mobile-menu__inner">
          <Link to="/" className="mobile-menu__logo">
            <h3>MobileMarket</h3>
          </Link>
          <div className="mobile-menu__menu">
            {/* Nav Menu Start */}
            <ul className="nav-menu flex-align nav-menu--mobile">
              <li
                onClick={() => handleMenuClick(0)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 0 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Home
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 0 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Home One
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/index-two"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Home Two
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => handleMenuClick(1)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 1 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Shop
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 1 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Shop
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/product-details"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Shop Details
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/product-details-two"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Shop Details Two
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => handleMenuClick(2)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 2 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Pages
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 2 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/cart"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Cart
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/checkout"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Checkout{" "}
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/account"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Account
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => handleMenuClick(3)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 3 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Blog
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 3 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/blog"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Blog
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      onClick={() => setActiveIndex(null)}
                      to="/blog-details"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Blog Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-menu__item">
                <Link to="/contact" className="nav-menu__link">
                  Contact Us
                </Link>
              </li>
            </ul>
            {/* Nav Menu End */}
          </div>
        </div>
      </div>
      {/* ==================== Mobile Menu End Here ==================== */}
      {/* ======================= Middle Header Two Start ========================= */}
      <header className="header-middle style-two bg-color-neutral">
        <div className="container container-lg">
          <nav className="header-inner flex-between">
            {/* Logo Start */}
            <div className="logo">
              <Link to="/" className="link">
                <h4 style={{ color: "white", margin: "0" }}>MobileMarket</h4>
              </Link>
            </div>
            {/* Logo End  */}
            {/* form Category Start */}
            <div className="flex-align gap-16">
              {/*} <div className="select-dropdown-for-home-two d-lg-none d-block">
                                {/* Dropdown Select Start 
                                <ul className="header-top__right style-two flex-align flex-wrap">
                                    <li className="on-hover-item border-right-item border-right-item-sm-space has-submenu arrow-white">
                                        {/* Display the selected language 
                                        <Link to="#" className="selected-text text-heading text-sm py-8">
                                            {selectedLanguage}
                                        </Link>
                                        <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--sm max-h-200 scroll-sm px-0 py-8">
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("English")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag1.png"
                                                        alt="English"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    English
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("Japan")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag2.png"
                                                        alt="Japan"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    Japan
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("French")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag3.png"
                                                        alt="French"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    French
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("Germany")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag4.png"
                                                        alt="Germany"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    Germany
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("Bangladesh")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag6.png"
                                                        alt="Bangladesh"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    Bangladesh
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleLanguageChange("South Korea")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag5.png"
                                                        alt="South Korea"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    South Korea
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="on-hover-item border-right-item border-right-item-sm-space has-submenu arrow-white">
                                        {/* Display the selected currency 
                                        <Link to="#" className="selected-text text-heading text-sm py-8">
                                            {selectedCurrency}
                                        </Link>
                                        <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--sm max-h-200 scroll-sm px-0 py-8">
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("USD")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag1.png"
                                                        alt="USD"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    USD
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("Yen")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag2.png"
                                                        alt="Yen"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    Yen
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("Franc")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag3.png"
                                                        alt="Franc"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    Franc
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("EURO")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag4.png"
                                                        alt="EURO"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    EURO
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("BDT")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag6.png"
                                                        alt="BDT"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    BDT
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                                    onClick={() => handleCurrencyChange("WON")}
                                                >
                                                    <img
                                                        src="assets/images/thumbs/flag5.png"
                                                        alt="WON"
                                                        className="w-16 h-12 rounded-4 border border-gray-100"
                                                    />
                                                    WON
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                {/* Dropdown Select End 
                            </div> */}
              <form
                action="#"
                className="flex-align flex-wrap form-location-wrapper"
              >
                <div className="search-category style-two d-flex h-48 search-form d-sm-flex d-none">
                  <select
                    defaultValue={1}
                    className="js-example-basic-single border border-gray-200 border-end-0 rounded-0 border-0"
                    name="state"
                  >
                    <option value={1}>All Categories</option>
                    <option value={1}>Samsung</option>
                    <option value={1}>iPhone &amp; Dairy</option>
                    <option value={1}>Itel</option>
                    <option value={1}>Vivo</option>
                    <option value={1}>Oppo</option>
                    <option value={1}>realme</option>
                    <option value={1}>Huwei</option>
                  </select>
                  <div className="search-form__wrapper position-relative">
                    <input
                      type="text"
                      className="search-form__input common-input py-13 ps-16 pe-18 rounded-0 border-0"
                      placeholder="Search for a product or brand"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-main-two-600 flex-center text-xl text-white flex-shrink-0 w-48 hover-bg-main-two-700 d-lg-flex d-none"
                  >
                    <i className="ph ph-magnifying-glass" />
                  </button>
                </div>
              </form>
            </div>
            {/* form Category start */}
          </nav>
        </div>
      </header>
      {/* ======================= Middle Header Two End ========================= */}
      {/* ==================== Header Two Start Here ==================== */}
      <header
        className={`header bg-white border-bottom border-gray-100 ${
          scroll && "fixed-header"
        }`}
      >
        <div className="container container-lg">
          <nav className="header-inner d-flex justify-content-center gap-8">
            <div className="flex-align menu-category-wrapper">
              {/* Menu Start  */}
              <div className="header-menu d-lg-block d-none">
                {/* Nav Menu Start */}
                <ul className="nav-menu flex-align ">
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="/" className="nav-menu__link">
                      Home
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="#" className="nav-menu__link">
                      About Us
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="#" className="nav-menu__link">
                      Contact Us
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="#" className="nav-menu__link">
                      Mobiles
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="#" className="nav-menu__link">
                      Mobile Prices
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="#" className="nav-menu__link">
                      Compare Mobiles
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item">
                    <Link to="#" className="nav-menu__link">
                      More Specs
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item">
                    <Link to="#" className="nav-menu__link">
                      Price Range
                    </Link>
                  </li>
                </ul>
                {/* Nav Menu End */}
              </div>
              {/* Menu End  */}
            </div>
          </nav>
        </div>
      </header>
      {/* ==================== Header End Here ==================== */}
    </>
  );
};

export default HeaderTwo;
