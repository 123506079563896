import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getCountdown } from "../helper/Countdown";

const SampleNextArrow = memo(function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={` ${className} slick-next slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
    >
      <i className="ph ph-caret-right" />
    </button>
  );
});

const SamplePrevArrow = memo(function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} slick-prev slick-arrow flex-center rounded-circle border border-gray-100 hover-border-neutral-600 text-xl hover-bg-neutral-600 hover-text-white transition-1`}
    >
      <i className="ph ph-caret-left" />
    </button>
  );
});

const Above45 = () => {
  const [timeLeft, setTimeLeft] = useState(getCountdown());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="deals-weeek pt-80">
      <div className="container container-lg">
        <div className="border border-gray-100 p-24 rounded-16">
          <div className="section-heading mb-24">
            <div className="flex-between flex-wrap gap-8">
              <h5 className="mb-0">45,000 To Above Latest Mobiles</h5>
              <div className="flex-align  gap-16">
                <Link
                  to="/"
                  className="text-sm fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          <div className="deals-week-slider arrow-style-two">
            <Slider {...settings}>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-card h-100 p-16 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link
                    to="/product-details-two"
                    className="product-card__thumb flex-center rounded-8 bg-gray-50 position-relative"
                  >
                    <img
                      src="assets/images/myimg/p1.png"
                      alt=""
                      className="w-auto max-w-unset"
                    />
                  </Link>
                  <div className="product-card__content mt-16">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link
                        to="/product-details-two"
                        className="link text-line-2"
                        tabIndex={0}
                      >
                        Realme C75 256GB
                      </Link>
                    </h6>

                    <div className="product-card__price my-20">
                      <span className="text-heading text-md fw-semibold ">
                        Rs 54,999{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Above45;
