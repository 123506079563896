import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const BannerTwo = () => {
  const settings = {
    dots: true,

    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div className="banner-two mt-5">
      <div className="container container-lg">
        <div className="row">
          <div className="col-md-8">
            <div className="banner-two-wrapper">
              <div
                className="  overflow-hidden position-relative arrow-center flex-grow-1 mb-0"
                style={{ minHeight: "500px" }}
              >
                <img
                  src="assets/images/myimg/bgmobile.jpg"
                  alt=""
                  className="banner-img position-absolute inset-block-start-0 inset-inline-start-0 w-100 h-100 z-n1 object-fit-cover"
                />
              </div>

              <h6 className="mt-5 p-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
                mollitia, molestiae quas vel.
              </h6>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className=" rounded-8 border border-gray-100 hover-border-gray-200 transition-1 mt-5"
              style={{
                padding: "10px 20px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-start "
                style={{ gap: "20px" }}
              >
                <img
                  src="assets/images/myimg/p1.png"
                  alt=""
                  style={{ width: "20%" }}
                />
                <h6>Realme C75 256GB</h6>
              </div>
            </div>
            <div
              className=" rounded-8 border border-gray-100 hover-border-gray-200 transition-1 mt-5"
              style={{
                padding: "10px 20px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-start "
                style={{ gap: "20px" }}
              >
                <img
                  src="assets/images/myimg/p1.png"
                  alt=""
                  style={{ width: "20%" }}
                />
                <h6>Realme C75 256GB</h6>
              </div>
            </div>
            <div
              className=" rounded-8 border border-gray-100 hover-border-gray-200 transition-1 mt-5"
              style={{
                padding: "10px 20px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-start "
                style={{ gap: "20px" }}
              >
                <img
                  src="assets/images/myimg/p1.png"
                  alt=""
                  style={{ width: "20%" }}
                />
                <h6>Realme C75 256GB</h6>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <Link
                to="/"
                className="text-lg fw-medium text-gray-700 hover-text-main-600 hover-text-decoration-underline"
              >
                View All Articles
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerTwo;
