import React from "react";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <footer className="footer py-80">
      <div className="container container-lg">
        <div className="footer-item-two-wrapper d-flex align-items-start flex-wrap justify-content-between">
          <div className="footer-item max-w-275">
            <div className="footer-item__logo">
              <Link to="/">
                {" "}
                <h4>MobileMarket</h4>
              </Link>
            </div>
            <p className="mb-24">
              MobileMarket become the largest Mobiles News Platform.
            </p>
            <div className="flex-align gap-16 mb-16">
              <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                <i className="ph-fill ph-phone-call" />
              </span>
              <Link
                to="/"
                className="text-md text-gray-900 hover-text-main-600"
              >
                +123 4444 234
              </Link>
            </div>
            <div className="flex-align gap-16 mb-16">
              <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                <i className="ph-fill ph-envelope" />
              </span>
              <Link
                to="/"
                className="text-md text-gray-900 hover-text-main-600"
              >
                xyz@.com
              </Link>
            </div>
            <div className="flex-align gap-16 mb-16">
              <span className="w-32 h-32 flex-center rounded-circle border border-gray-100 text-main-two-600 text-md flex-shrink-0">
                <i className="ph-fill ph-map-pin" />
              </span>
              <span className="text-md text-gray-900 ">123 address xyz</span>
            </div>
          </div>
          <div className="footer-item">
            <h6 className="footer-item__title">Links</h6>
            <ul className="footer-menu">
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Home
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  About Us
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-item">
            <h6 className="footer-item__title">About Links</h6>
            <ul className="footer-menu">
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Mobiles
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Mobile Prices
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Compare Mobiles
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  Price Range
                </Link>
              </li>
              <li className="mb-16">
                <Link to="/" className="text-gray-600 hover-text-main-600">
                  More Specs
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-item">
            <h6 className="">Social Links</h6>
            <ul className="flex-align gap-16">
              <li>
                <Link
                  to="#"
                  className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                >
                  <i className="ph-fill ph-facebook-logo" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                >
                  <i className="ph-fill ph-twitter-logo" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                >
                  <i className="ph-fill ph-instagram-logo" />
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="w-44 h-44 flex-center bg-main-two-50 text-main-two-600 text-xl rounded-8 hover-bg-main-two-600 hover-text-white"
                >
                  <i className="ph-fill ph-linkedin-logo" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
