import React from "react";
import { Link } from "react-router-dom";

const PromotionalTwo = () => {
  return (
    <section className="promotional-banner mt-32">
      <div className="container container-lg">
        <div className="row gy-4">
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">Camera Mobiles</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">Triple Sim</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">WiFi Mobiles</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">Dual Sim</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">Bluetooth</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="position-relative rounded-16 overflow-hidden z-1 p-32"
              style={{ border: "1px solid" }}
            >
              <div className="flex-between flex-wrap gap-16">
                <div className="">
                  <h6 className="mb-0">FM Radio</h6>
                  <Link
                    to="/"
                    className="d-inline-flex align-items-center gap-8 mt-16 text-heading text-md fw-medium border border-top-0 border-end-0 border-start-0 border-gray-900 hover-text-main-two-600 hover-border-main-two-600"
                  >
                    View All
                    <span className="icon text-md d-flex">
                      <i className="ph ph-plus" />
                    </span>
                  </Link>
                </div>
                <div className="pe-xxl-4">
                  <img src="assets/images/myimg/p1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromotionalTwo;
